var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-badge",
    {
      staticClass: "full-width",
      attrs: {
        value: !!_vm.assignment.needsAttention,
        color: "error",
        icon: "mdi-exclamation-thick",
        left: "",
        overlap: "",
        bordered: "",
      },
    },
    [
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "my-4" },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      class: `${this.status.color.split(" ")[0]}-border `,
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ma-0 clickable",
                          class: `${this.status.color} white--text ${
                            this.status.color.split(" ")[0]
                          }-border-bottom`,
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(
                                `/driver/${_vm.assignment.id}`
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v("TRIP #"),
                              ]),
                              _c("div", { staticClass: "text-h5" }, [
                                _vm._v(_vm._s(_vm.assignment.tripRequestId)),
                              ]),
                              _vm.assignment.outOfState
                                ? _c(
                                    "v-icon",
                                    {
                                      class: `${
                                        _vm.status.color.split(" ")[0]
                                      }--text text--${
                                        _vm.status.color.split(" ")[1]
                                      } mx-1`,
                                    },
                                    [_vm._v(" mdi-weather-night ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { staticClass: "right", attrs: { cols: "8" } },
                            [
                              _c(
                                "div",
                                { staticClass: "text-subtitle-1 text-right" },
                                [_vm._v(_vm._s(_vm.tripHours) + " TOTAL HRS")]
                              ),
                              _c(
                                "div",
                                { staticClass: "text-subtitle-1 text-right" },
                                [_vm._v("TOTAL MI")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1" },
                              [
                                _c("v-icon", [_vm._v("mdi-account")]),
                                _vm._v(_vm._s(_vm.driver)),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1" },
                              [
                                _c("v-icon", [_vm._v("mdi-bus")]),
                                _vm._v(_vm._s(_vm.vehicle)),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v("(" + _vm._s(_vm.vehicleType) + ")"),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1 text-right" },
                              [
                                _vm._v(
                                  _vm._s(_vm.leaveDate) +
                                    " - " +
                                    _vm._s(_vm.leaveTime)
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "text-subtitle-1 text-right" },
                              [
                                _vm._v(
                                  _vm._s(_vm.returnDate) +
                                    " - " +
                                    _vm._s(_vm.returnTime)
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Route")]
                              ),
                              _vm._l(_vm.route, function (stop, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "text-subtitle-1 px-4",
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(stop.icon) + " "),
                                    ]),
                                    _vm._v(_vm._s(stop.name) + " "),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Trip Details")]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Type:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.tripTypesById[
                                                _vm.tripRequest.tripTypeId
                                              ].name
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Event:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tripRequest.tripEventIds
                                                  .map(
                                                    (e) =>
                                                      _vm.tripEventsById[e].name
                                                  )
                                                  .join(", ")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Passenger Details")]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("# Adults/Students:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.tripRequest.totalAdults
                                            ) +
                                              " / " +
                                              _vm._s(
                                                _vm.tripRequest.totalStudents
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Special Needs Student(s):")]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("0")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _vm.tripRequest.gradeLevels.length
                                      ? _c(
                                          "div",
                                          { staticClass: "text-subtitle-1" },
                                          [_vm._v("Grades:")]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.tripRequest.gradeLevels.length
                                        ? _c(
                                            "div",
                                            { staticClass: "text-subtitle-1" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.grades) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Other")]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Wheelchair Lift:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tripRequest
                                                  .numWheelchairSlot > 0
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Special Equipment:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [_vm._v(_vm._s(_vm.specEq))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-4", attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "text-subtitle-1" },
                                      [_vm._v("Special Indicators:")]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "center-vertical",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle-1" },
                                        [_vm._v(_vm._s(_vm.specIns))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-row",
                        { staticClass: "px-2 my-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-overline font-weight-bold",
                                },
                                [_vm._v("Notes")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "center-vertical",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm.tripRequest.vehDriverInfo.length
                                    ? _c(
                                        "p",
                                        { staticClass: "text-subtitle-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tripRequest.vehDriverInfo
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        { staticClass: "text-subtitle-1" },
                                        [_vm._v("None")]
                                      ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.assignment.status == 0
                    ? [
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          [
                            _vm.showDecline
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "error" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.accept(false)
                                      },
                                    },
                                  },
                                  [_vm._v(" Decline ")]
                                )
                              : _vm._e(),
                            _c("v-spacer"),
                            _vm.showAccept
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.accept(true)
                                      },
                                    },
                                  },
                                  [_vm._v(" Accept ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }